import { onlyTruckNumValidator } from '@/core'

export const rulesConfirmTimeslot = {
  num_auto: [
    {
      required: true,
      message: 'Заполните номер авто',
      trigger: 'blur',
    },
    {
      required: true,
      validator: (rule, value, callback) => {
        onlyTruckNumValidator(value) ? callback(new Error()) : callback()
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: 'blur',
    },
  ],
  exporter_id: [
    {
      required: true,
      message: 'Выберите экспортера',
      trigger: 'blur',
    },
  ],
  culture_id: [
    {
      required: true,
      message: 'Выберите культуру',
      trigger: 'blur',
    },
  ],
  type: [
    {
      required: true,
      message: 'Выберите тип авто',
      trigger: 'blur',
    },
  ],
  user_created_phone: [
    {
      required: true,
      message: 'Заполните номер телефона',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Заполните номер телефона',
      trigger: 'blur',
    },
  ],
  detach: [
    {
      required: true,
      message: 'Выберите состояние',
      trigger: 'change',
    },
  ],
}
