<template>
  <Dialog
    :name="name"
    width="475px"
    class="timeslot-viewing"
    @close="handleClearFields"
  >
    <template #title>
      <div>Просмотр тайм слота</div>
    </template>

    <div class="timeslot-viewing__main">
      <iq-title class-name="mobileHeader">Тайм слот: {{ getDate }}</iq-title>

      <el-form
        ref="formAddTimeslot"
        :model="formAddTimeslot"
        :rules="rules"
        label-width="175px"
        class="dialog-edit-timeslot"
        @submit.native.prevent
      >
        <iq-input-form
          v-model="formAddTimeslot.num_auto"
          label="Номер Авто"
          prop="num_auto"
          label-width="175px"
          input-width="265px"
          :rules="rules.num_auto"
          class="m-b-20"
        />

        <iq-select-form
          v-model="formAddTimeslot.type"
          label="Тип авто"
          prop="type"
          :rules="rules.type"
          :options="autoType"
          disabled
          key-label="title"
          key-value="val"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />

        <iq-select-form
          v-model="formAddTimeslot.culture_id"
          label="Культура"
          prop="culture_id"
          :rules="rules.culture_id"
          :options="cultureList"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />

        <iq-select-form
          v-model="formAddTimeslot.exporter_id"
          label="Экспортер"
          disabled
          :rules="rules.exporter_id"
          :options="exportersList"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />

        <iq-input-form
          v-model="formAddTimeslot.truck_status"
          label="Статус"
          label-width="175px"
          input-width="265px"
          disabled
          class="m-b-20"
        />

        <iq-input-form
          v-model="formAddTimeslot.date_cre"
          label="Дата получения"
          label-width="175px"
          input-width="265px"
          disabled
          class="m-b-20"
        />

        <iq-input-form
          v-model="formAddTimeslot.phone"
          v-mask="'+7 ### ###-##-##'"
          label="Телефон водителя"
          prop="phone"
          label-width="175px"
          input-width="265px"
          :rules="rules.phone"
          class="m-b-20"
        />

        <iq-select-form
          v-model="formAddTimeslot.detach"
          label="Проезд"
          prop="detach"
          :rules="rules.detach"
          :options="[
            { id: 0, name: 'Разрешен' },
            { id: 1, name: 'Отказан' },
          ]"
          key-label="name"
          key-value="id"
          label-width="175px"
          input-width="265px"
          :is-super-important-label-width="true"
        />
      </el-form>

      <div class="timeslot-viewing__timeslot-wrap-btn">
        <iq-button
          size="small"
          :disabled="isLoadingPostTimeslots"
          @onClick="handleConfirmTimeslot"
        >
          Подтвердить приезд авто
        </iq-button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import {
  CONFIRM_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
} from '@/views/manager/store/actions'
import { DATE_FORMAT_FULL_RU, DATE_FORMAT_SHORT } from '@/constants/date'
import {
  GET_CULTURE_FROM_STATE,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import { GET_IS_USE_COMPLEX_PARKING_STATUSES } from '@/store/actions'
import { MANAGER_TIMESLOT_VIEWING } from '@/constants/dialogs'
import { autoType } from '@/constants/auto-type'
import { getDateTzFromUnix, getFormattedTzDate } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { rulesConfirmTimeslot } from '@/views/manager/components/dialogs/dialog-timeslot-viewing/data/dialogTimeslotViewing'
import { timeslotStatuses } from '@/views/manager/data/manager'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqInputForm from '@/UI/form/input/IqInputForm'
import IqSelectForm from '@/UI/select/IqSelectForm'
import IqTitle from '@/UI/typography/IqTitle'
export default {
  name: 'DialogTimeslotViewing',
  components: { IqButton, IqSelectForm, IqInputForm, IqTitle, Dialog },
  data() {
    return {
      name: MANAGER_TIMESLOT_VIEWING,
      formAddTimeslot: {
        num_auto: null,
        exporter_id: null,
        culture_id: null,
        truck_status: null,
        date_cre: null,
        phone: null,
        type: null,
        detach: null,
      },
      rules: rulesConfirmTimeslot,
      autoType,
      dateFormat: getDateTzFromUnix,
    }
  },
  computed: {
    ...mapGetters({
      isLoadingPostTimeslots: GET_IS_LOADING_POST_TIMESLOTS,
      cultureList: GET_CULTURE_FROM_STATE,
      exportersList: GET_EXPORTERS_ALL,
      isShowDocumentCheckbox: GET_IS_USE_COMPLEX_PARKING_STATUSES,
    }),
    dialogData() {
      return this.getDialog(this.name)?.data || {}
    },
    getDate() {
      return this.dateFormat(this.dialogData.time, DATE_FORMAT_SHORT)
    },
  },
  watch: {
    dialogData: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.setFormFields(
            val.num_auto,
            val.exporter_id,
            val.culture_id,
            val.truck_status,
            getFormattedTzDate(val.date_cre, DATE_FORMAT_FULL_RU),
            val.phone,
            val.truck_type_code,
            val.truck_status === 'detached' ? 1 : 0,
          )
        } else {
          this.setFormFields()
        }
      },
    },
  },
  methods: {
    ...mapActions({ confirmTimeslot: CONFIRM_TIMESLOT }),
    handleConfirmTimeslot() {
      this.$refs.formAddTimeslot.validate(valid => {
        if (valid) {
          const requestData = {
            timeslot_id: this.dialogData.id,
            unload_id: this.dialogData.unload_id,
            setFormFields: this.setFormFields,
            nameDialog: this.name,
            phone: this.formAddTimeslot.phone,
            num_auto: this.formAddTimeslot.num_auto,
            exporter_id: this.formAddTimeslot.exporter_id,
            culture_id: this.formAddTimeslot.culture_id,
            truck_type_code: this.formAddTimeslot.type,
            detach: this.formAddTimeslot.detach,
          }

          this.confirmTimeslot(requestData)
        }
      })
    },
    setFormFields(
      num_auto = '',
      exporter_id = '',
      culture_id = '',
      truck_status = '',
      date_cre = '',
      phone = '',
      type = '',
      detach = null,
    ) {
      this.formAddTimeslot.num_auto = num_auto
      this.formAddTimeslot.exporter_id = exporter_id
      this.formAddTimeslot.culture_id = culture_id
      this.formAddTimeslot.truck_status = timeslotStatuses[truck_status]?.title
      this.formAddTimeslot.date_cre = date_cre
      this.formAddTimeslot.phone = phone
      this.formAddTimeslot.type = type
      this.formAddTimeslot.detach = detach
    },
    handleClearFields() {
      this.setFormFields()
      this.$refs.formAddTimeslot.clearValidate()
    },
  },
}
</script>

<style lang="sass">
.timeslot-viewing
  display: flex
  justify-content: space-between
  align-items: flex-start
  padding-bottom: 15px

  &__param-item
    flex: 0 0 100%
    display: flex
    justify-content: flex-start
    align-items: center
    & + &
      margin-top: 10px

  &__param-title
    flex: 0 0 40%
    font-weight: bold

  &__param-val
    flex: 0 0 60%
  //
  &__timeslot-heading
    flex: 0 0 100%

  &__timeslot-wrap-btn
    flex: 0 0 100%
    display: flex
    justify-content: center

  &__btn-wrap
    margin-left: 30px
  &__btn
    font-size: 28px
    cursor: pointer
    color: $color-black
    transition: all .3s ease
    padding: 0 !important
    border: none
    &:hover, &:focus
      color: $btn-orange
      background: transparent
</style>
